import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Open Sans"',
].join(',');

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: fontFamily,
  },
  palette: {
    primary: {
      main: '#24cd99',
      contrastText: '#fff',
    },
    deck1: {
      main: '#10b981',
      50: '#ecfdf5',
      100: '#bae6d1',
    },
    deck2: {
      main: '#ef4444',
      50: '#fef2f2',
      100: '#ffc9ce'
    },
    deck3: {
      main: '#f59e0b',
      50: '#fffbeb',
      100: '#f9df83',
    },
    deck4: {
      main: '#3b82f6',
      50: '#eff6ff',
      100: '#bdddff',
    },
    deck5: {
      main: '#3b82f6',
      50: 'white',
      100: '#bdddff',
    },
    pdeck: {
      main: '#71717a',
      50: '#fff',
    },
    yellow: {
      main: '#ff9800',
    },
    black: {
      main: '#000',
      contrastText: '#fff',
    },
    grey: {
      main: grey[500],
      contrastText: '#fff',
    }
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
      styleOverrides: {
        root: {
          '.MuiDrawer-root &': {
            maxWidth: '90vw',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 20px',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
      },
    },
  },
});



export default theme;
