import { getToken, removeToken } from './token';
import { rateLimitHandler } from './rateLimit';

export const apiUrl = process.env.REACT_APP_API_URL;

export const api = (input, init = {}) => {
  const idToken = getToken();
  const authHeaders = idToken
    ? {
        Accept: 'application/json',
        Authorization: `Bearer ${idToken}`,
      }
    : {};
  return rateLimitHandler(() =>
    fetch(`${apiUrl}${input}`, {
      ...init,
      headers: {
        ...init?.headers,
        ...authHeaders,
      },
    }).then(res => {
      if (res.status === 401) {
        removeToken();

        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      } else if (res.status === 503) {
        if (window.location.pathname !== '/maintenance') {
          window.location.href = '/maintenance';
        }
        return null;
      }
      return res;
    })
  );
};

export default api;

export const get = async (url, params = {}, options = {}) => {
  const query = Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  const finalUrl = query ? `${url}?${query}` : url;

  const data = await api(finalUrl, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    ...options,
  });

  return data;
};

export const post = async (url, body = {}, options = {}) => {
  const data = await api(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    ...options,
  });

  return data;
};

export const put = async (url, body = {}, options = {}) => {
  const data = await api(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    ...options,
  });

  return data;
};

export const patch = async (url, body = {}, options = {}) => {
  const data = await api(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    ...options,
  });

  return data;
};

export const del = async (url, options = {}) => {
  const data = await api(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    ...options,
  });

  return data;
};

export const swrGet = url =>
  api(url).then(res => {
    if (res.status < 400) {
      return res.json();
    } else {
      throw Error(res);
    }
  });

export const buildSearchParams = data => {
  const searchParams = [];

  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof Object && !Array.isArray(value)) {
      Object.entries(value).forEach(([k, v]) => v && searchParams.push([`${key}[${k}]`, v]));
    } else if (Array.isArray(value)) {
      value.forEach(v => searchParams.push([`${key}[]`, v]));
    } else if (value) {
      searchParams.push([key, value]);
    }
  });

  return searchParams.map(([key, value]) => `${key}=${value}`);
};

export const urlWithParams = (url, params) => {
  const searchParams = buildSearchParams(params);

  return searchParams.length ? url + '?' + searchParams.join('&') : url;
};

export { get as apiGet, post as apiPost, put as apiPut, patch as apiPatch, del as apiDel };
