import Router from './Router';
import theme from './theme';
import { ThemeProvider } from '@mui/material';
import { SWRConfig } from 'swr';
import { swrGet } from 'utils/api';
import 'intro.js/introjs.css';
import 'intro.js/introjs-rtl.css';
import 'App.css';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'pages/error/Error';
//import { getProvider } from 'utils/swr';
import { post as apiPost } from 'utils/api';

function App() {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: true,
        revalidateIfStale: true,
        dedupingInterval: 5 * 1000,
        refreshWhenHidden: false,
        refreshWhenOffline: false,
        fetcher: swrGet,
        //provider: () => getProvider(),
      }}
    >
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(error, info) => {
            apiPost('/log', {
              message: error.message,
              stack: error.stack,
              uri: window.location.href,
            }).catch(err => {});
            console.log({ error, info });
          }}
        >
          <Router />
        </ErrorBoundary>
      </ThemeProvider>
    </SWRConfig>
  );
}

export default App;
