import React from 'react';
import ReactDOM from 'react-dom/client';
//import "./index.css";
import App from './App';
import RTL from './RTL';
import initI18n from './i18n';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

initI18n().then(() => {
  root.render(
    <RTL>
      <App />
    </RTL>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
