import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import AuthRoute from './components/AuthRoute';

//import Home from 'pages/home/Home';
//import Contact from 'pages/contact/Contact';
//import Login from 'pages/auth/Login';
//import Logout from 'pages/auth/Logout';
//import Register from 'pages/auth/Register';
//import ResetPassword from 'pages/auth/ResetPassword';
//import ForgotPassword from 'pages/auth/ForgotPassword';

//import Dashboard from 'pages/dashboard/Dashboard';
//import Profile from 'pages/profile/Profile';
//import Simulations from 'pages/simulations/Simulations';
//import Decks from 'pages/decks/Decks';
//import Learn from 'pages/learn/Entry';
//import Search from 'pages/search/Search';
//import LearnStats from 'pages/statistic/learn/Learn';
//import SimuStats from 'pages/statistic/simulations/Stats';
//import Guides from 'pages/guides/Guides';
//import Guide from 'pages/guides/Guide';
//import Posts from 'pages/posts/Posts';
//import Post from 'pages/posts/Post';
//import Arena from 'pages/arena/Arena';
//import Error404 from 'pages/error/Error404';
//import SimulationResults from 'pages/simulations/results/Results';
//import SimulationResult from 'pages/simulations/results/View';
//import PersonalDeck from 'pages/personal-deck/PersonalDeck';
//import Training from 'pages/training/Entry';
//import StudyGoal from 'pages/goal/Goal';
//import UserCards from 'pages/user-cards/UserCards';

const AuthRoute = lazy(() => import('components/AuthRoute'));
const MemberRoute = lazy(() => import('components/MemberRoute'));

const Home = lazy(() => import('pages/home/Home'));
const Contact = lazy(() => import('pages/contact/Contact'));
const Login = lazy(() => import('pages/auth/Login'));
const Logout = lazy(() => import('pages/auth/Logout'));
const Register = lazy(() => import('pages/auth/Register'));
const ResetPassword = lazy(() => import('pages/auth/ResetPassword'));
const ForgotPassword = lazy(() => import('pages/auth/ForgotPassword'));
const EmailVerify = lazy(() => import('pages/auth/VerifyEmail'));

const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Profile = lazy(() => import('pages/profile/Profile'));
const Simulations = lazy(() => import('pages/simulations/list/Simulations'));
const Decks = lazy(() => import('pages/decks/Decks'));
const Learn = lazy(() => import('pages/learn/Entry'));
const LearnDeck = lazy(() => import('pages/learn/Deck'));
const Search = lazy(() => import('pages/search/Search'));
const LearnStats = lazy(() => import('pages/statistic/learn/Learn'));
const SimuStats = lazy(() => import('pages/statistic/simulations/Stats'));
const Guides = lazy(() => import('pages/guides/Guides'));
const Guide = lazy(() => import('pages/guides/Guide'));
const Posts = lazy(() => import('pages/posts/Posts'));
const Post = lazy(() => import('pages/posts/Post'));
const Arena = lazy(() => import('pages/arena/Arena'));
const AdminRouter = lazy(() => import('admin/Router'));
const Error404 = lazy(() => import('pages/error/Error404'));
const SimulationResults = lazy(() => import('pages/simulations/results/Results'));
const SimulationResult = lazy(() => import('pages/simulations/results/View'));
const PersonalDeck = lazy(() => import('pages/personal-deck/PersonalDeck'));
const Training = lazy(() => import('pages/training/Entry'));
const StudyGoal = lazy(() => import('pages/goal/Goal'));
const Maintenance = lazy(() => import('pages/maintenance/Maintenance'));
const UserCards = lazy(() => import('pages/user-cards/UserCards'));
const OrderStatus = lazy(() => import('pages/shop/OrderStatus'));

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<EmailVerify />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route element={<AuthRoute />}>
            <Route element={<MemberRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/decks" element={<Decks />} />
              <Route path="/training" element={<Training />} />
              <Route path="/personal-deck" element={<PersonalDeck />} />
              <Route path="/simulations" element={<Simulations />} />
              <Route path="/simulations/:id" element={<Simulations />} />
              <Route path="/simulation-results" element={<SimulationResults />} />
              <Route path="/simulation-results/:id" element={<SimulationResult />} />
              <Route path="/learn" element={<Learn />} />
              <Route path="/learn/:id" element={<LearnDeck />} />
              <Route path="/search" element={<Search />} />
              <Route path="/statistics" element={<LearnStats />} />
              <Route path="/statistics/simulations" element={<SimuStats />} />
              <Route path="/study-progress/*" element={<StudyGoal />} />
              <Route path="/user-cards" element={<UserCards />} />
              <Route path="/arena/*" element={<Arena />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/guides" element={<Guides />} />
              <Route path="/guides/:id" element={<Guide />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/posts/:id" element={<Post />} />
            </Route>
            <Route path="/order/:id" element={<OrderStatus />} />
            <Route path="/admin/*" element={<AdminRouter />} />
          </Route>
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
