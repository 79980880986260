import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import bug_fixing from './bug_fixing.svg';

export default function Error404Page({ resetErrorBoundary }) {
  const { t } = useTranslation();

  const handleClick = () => {
    resetErrorBoundary && resetErrorBoundary();
    window.location.href = '/dashboard';
  };

  return (
    <Box
      sx={{
        background: '#fff',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'auto',
        p: 4,
      }}
    >
      <Box
        as="img"
        src={bug_fixing}
        sx={{
          maxWidth: 400,
          height: 'auto',
        }}
      />
      <Typography variant="h4" sx={{ maxWidth: 400, textAlign: 'center', my: 4 }}>
        {t('error.boundary.text')}
      </Typography>
      <Button onClick={handleClick} size="large">
        {t('error.boundary.button')}
      </Button>
    </Box>
  );
}
